import React, { Component } from 'react';
import { Index } from 'elasticlunr';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Col, Row } from 'styled-bootstrap-grid';
import { SearchContext } from './searchContext';

const SearchInput = styled.input`
  border: 0;
  background-color: transparent;

  border-bottom: 1px solid #013bd2;
  color: #013bd2;

  -webkit-appearance: none;
  border-radius: 0;

  font-size: 16px;

  padding: 5px 0;
  margin-bottom: 15px;

  width: 100%;

  &:focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    color: #013bd2;
  }
  ::-moz-placeholder {
    color: #013bd2;
  }
  :-ms-input-placeholder {
    color: #013bd2;
  }
  :-moz-placeholder {
    color: #013bd2;
  }
`;

const NewsArticle = styled.div`
  & h3 {
    padding: 15px 0;
  }
`;

const Exceprt = styled.div`
  padding: 0 0 50px 0;
`;

// Search component
export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: ``,
      results: [],
    };
  }

  render(props) {
    return (
      <SearchContext.Consumer>
        {({ updateResults }) => (
          <React.Fragment>
            <Row>
              <Col col={12} md={6}>
                <SearchInput
                  type="text"
                  value={this.state.query}
                  onChange={evt => {
                    this.search(evt, updateResults);
                  }}
                  placeholder="Search"
                />
              </Col>
            </Row>
            {/* <Row>
              {this.state.results.length >= 1 ? (
                <Col col={12}>
                  <p>Search Results</p>
                </Col>
              ) : (
                <Col col={12}>
                  <p>Sorry No Results</p>
                </Col>
              )}
            </Row> */}
            <Row>
              {this.state.results.map(page => (
                <Col col={12} md={6} key={page.id}>
                  <NewsArticle>
                    <Link className="blogUrl" to={`/news/${page.uid}`}>
                      <img src={page.image} alt={page.title} />
                    </Link>
                    <h3>{page.title}</h3>
                    <Exceprt>{page.excerpt}</Exceprt>
                  </NewsArticle>
                </Col>
              ))}
            </Row>
          </React.Fragment>
        )}
      </SearchContext.Consumer>
    );
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex);

  search = (evt, updateResults) => {
    const query = evt.target.value;
    this.index = this.getOrCreateIndex();
    this.setState(
      {
        query,
        // Query the index with search string to get an [] of IDs
        results: this.index
          .search(query, { expand: true }) // Accept partial matches
          // Map over each ID and return the full document
          .map(({ ref }) => this.index.documentStore.getDoc(ref)),
      },
      () => {
        updateResults(this.state.results);
      }
    );
  };
}
