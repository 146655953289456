import React from 'react';
import { graphql, Link } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';

// Previews
import { withPreview } from 'gatsby-source-prismic';

import styled from 'styled-components';
import Subtitle from '../components/Text/subtitle';
import SearchWrapper from '../components/Search/search-wrapper';
import { SearchContext } from '../components/Search/searchContext';

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.textAlign};

  @media (max-width: 767px) {
    justify-content: ${(props) => props.textAlignMobile};
  }
`;

const PrevArrow = styled.div`
  display: inline-block;

  width: 35px;
  height: 15px;
  background-image: url('/images/slideshow-next.svg');

  margin-right: 20px;
`;

const NextArrow = styled.div`
  display: inline-block;

  width: 35px;
  height: 15px;
  background-image: url('/images/slideshow-prev.svg');

  margin-left: 20px;
`;

const NewsArticle = styled.div`
  & h3 {
    font-family: 'BatonTurbo', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
    text-transform: none;
    font-size: 20px;

    padding: 15px 0;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.667%;
  }
  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: rgba(69, 109, 232, 0.4);
  }
`;

const Exceprt = styled.div`
  padding: 0 50px 50px 50px;

  font-size: 16px;
  line-height: 24px;

  @media (max-width: 920px) {
    padding: 0 20px 50px 20px;
  }

  @media (max-width: 800px) {
    padding: 0 0 50px 0;
  }
`;

const NavLink = (props) => {
  if (!props.test) {
    return (
      <NavWrapper
        textAlign={props.textAlign}
        textAlignMobile={props.textAlignMobile}
      >
        <Link to={`/news/${props.url}`}>
          {props.arrowDirection === 'prev' && <PrevArrow />}
          {props.text}
          {props.arrowDirection === 'next' && <NextArrow />}
        </Link>
      </NavWrapper>
    );
  } else {
    return (
      <NavWrapper
        textAlign={props.textAlign}
        textAlignMobile={props.textAlignMobile}
      >
        <span style={{ lineHeight: 1 }}>
          {props.arrowDirection === 'prev' && <PrevArrow />}
          {props.text}
          {props.arrowDirection === 'next' && <NextArrow />}
        </span>
      </NavWrapper>
    );
  }
};

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      updateResults: this.updateResults,
    };

    this.updateResults = this.updateResults.bind(this);
  }

  updateResults = (results) => {
    this.setState({ results });
  };

  render(props) {
    const { group, index, first, last } = this.props.pageContext;
    const previousUrl = index - 1 === 1 ? '/' : (index - 1).toString();
    const nextUrl = (index + 1).toString();

    const articles = group.map((article, index) => {
      return (
        <Col col={12} md={6} key={article.node.prismicId}>
          <NewsArticle>
            <Link className="blogUrl" to={`/news/${article.node.uid}`}>
              <ImageContainer>
                <div className="content">
                  <img
                    src={article.node.data.image.url}
                    alt={article.node.data.image.alt}
                    loading="lazy"
                  />
                </div>
              </ImageContainer>
            </Link>
            <h3>{article.node.data.title.text}</h3>

            <Exceprt>{article.node.data.excerpt.text}</Exceprt>
          </NewsArticle>
        </Col>
      );
    });

    return (
      <React.Fragment>
        <SearchContext.Provider value={this.state}>
          <Helmet title={'News – Everyone Agency'} />

          <Col col={12}>
            <Row justifyContent="center">
              <Subtitle
                text={this.props.data.prismicNews.data.text}
                padding={'100px 0'}
              />
            </Row>
            <Row>
              <Col col={12}>
                <SearchWrapper />
              </Col>
            </Row>
            {this.state.results.length === 0 && (
              <React.Fragment>
                <Row>{articles}</Row>

                <Row css={{ marginTop: '75px' }} alignItems="center">
                  <Col col={6} className="previousLink">
                    {first === false && (
                      <NavLink
                        test={first}
                        url={previousUrl}
                        text="Newer"
                        textAlign={'flex-end'}
                        textAlignMobile={'flex-start'}
                        arrowDirection={'prev'}
                      />
                    )}
                  </Col>
                  <Col col={6} className="nextLink">
                    <NavLink
                      test={last}
                      url={nextUrl}
                      text="Older"
                      textAlign={'flex-start'}
                      textAlignMobile={'flex-end'}
                      arrowDirection={'next'}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Col>
        </SearchContext.Provider>
      </React.Fragment>
    );
  }
}

export default withPreview(News);

export const pageQuery = graphql`
  query NewsQuery {
    prismicNews {
      data {
        text {
          html
        }
      }
    }
  }
`;
